import { Wheel } from '@wheelroom/core'
import { styledSystem } from '@wheelroom/styled-system'
import React, { SVGProps } from 'react'
export interface IconProps extends SVGProps<SVGSVGElement> {
  ncss: any
  wheel: Wheel
}
const componentId = 'svg-logo-wit'
export const SvgLogoWit = (props: IconProps) => {
  const strokeWidth = props.strokeWidth || 2
  const css = styledSystem(
    props.wheel.wrSystemConfig,
    props.wheel.wrSystemTheme,
    {
      ncss: props.ncss,
    }
  )
  console.log('css', css)
  return (
    <svg
      viewBox="0 0 800 382"
      fill="none"
      css={css}
      id={componentId}
      stroke="currentColor"
      strokeWidth={strokeWidth}
      width="100%"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M278.835 78.123c-17.622 26.738-27.464 66.491-27.464 116.117v15.686a3.137 3.137 0 01-3.137 3.137h-84.705v-21.961c0-8.663 7.023-15.686 15.686-15.686h31.965a3.16 3.16 0 003.146-2.988c2.53-44.877 13.106-84.722 33.075-115.021C271.165 21.348 307.576 0 354.9 0c84.134 0 181.905 66.005 194.037 169.444.187 1.599 1.535 2.835 3.145 2.835h127.335c17.326 0 31.372 14.046 31.372 31.372v3.137a3.137 3.137 0 01-3.137 3.138H515.683a3.137 3.137 0 01-3.137-3.138v-15.686c0-90.958-84.28-153.455-157.646-153.455-34.293 0-59.058 14.671-76.065 40.476z"
        fill="#fff"
      />
      <path
        d="M330.23 381.785c-22.014 0-38.524-5.847-49.531-17.542-11.007-11.694-16.51-29.065-16.51-52.11 0-24.078 5.417-41.62 16.252-52.627 11.007-11.006 27.603-16.51 49.789-16.51 22.185 0 38.868 5.676 50.046 17.026 11.179 11.179 16.769 28.549 16.769 52.111 0 22.701-5.762 39.986-17.285 51.852-11.522 11.867-28.033 17.8-49.53 17.8zm0-30.698c9.459 0 16.596-3.268 21.411-9.803 4.816-6.708 7.224-16.425 7.224-29.151 0-13.931-2.408-23.82-7.224-29.667-4.643-6.019-11.78-9.029-21.411-9.029-9.975 0-17.198 3.01-21.67 9.029-4.3 5.847-6.449 15.736-6.449 29.667 0 13.242 2.235 23.045 6.707 29.409 4.644 6.363 11.781 9.545 21.412 9.545zM712.031 261.054c2.58-2.235 7.052-4.729 13.415-7.481 6.535-2.752 13.759-5.073 21.67-6.965 8.083-2.064 15.822-3.096 23.217-3.096 14.275 0 24.164 2.15 29.667 6.45v28.119c-6.879-1.548-16.768-2.322-29.667-2.322-8.083 0-14.704.43-19.864 1.29v100.867h-38.438V261.054z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.843 177.133c-8.663 0-15.686 7.023-15.686 15.687v20.392h-.06v36.75c-7.568-4.472-17.457-6.708-29.668-6.708-20.465 0-36.03 6.02-46.693 18.058C5.246 273.179 0 289.947 0 311.617c0 25.797 6.02 43.941 18.058 54.432 12.21 10.491 29.409 15.737 51.595 15.737 10.49 0 20.38-1.462 29.666-4.386 9.288-2.924 16.769-7.223 22.444-12.899 5.676-5.675 8.513-12.554 8.513-20.637V177.133h-22.433zm-35.869 97.078c6.536 0 13.243 1.806 20.122 5.417v60.108c0 3.096-1.892 5.504-5.675 7.223-3.784 1.72-8.857 2.58-15.22 2.58-11.695 0-20.036-3.096-25.024-9.287-4.987-6.363-7.481-15.564-7.481-27.603 0-13.931 2.666-23.82 7.997-29.667 5.332-5.847 13.759-8.771 25.281-8.771z"
        fill="#fff"
      />
      <path
        d="M165.603 261.054c2.579-2.236 7.051-4.729 13.414-7.481 6.536-2.752 13.759-5.073 21.67-6.965 8.083-2.064 15.822-3.096 23.218-3.096 14.274 0 24.163 2.15 29.666 6.449v28.119c-6.879-1.547-16.768-2.321-29.666-2.321-8.084 0-14.705.43-19.864 1.29v100.867h-38.438V261.054zM551.987 247.382l-54.432 130.534h-38.438l-54.432-130.534h41.534l34.568 88.226 30.699-88.226h40.501zM624.959 242.996c40.76 0 60.882 26.571 60.366 79.714h-86.163c3.268 18.058 15.393 27.087 36.374 27.087 6.364 0 12.555-.86 18.574-2.58 6.192-1.892 12.727-4.299 19.606-7.223v33.02c-14.102 5.848-29.925 8.771-47.467 8.771-13.242 0-24.851-2.665-34.826-7.997-9.975-5.503-17.714-13.328-23.218-23.475-5.503-10.147-8.255-22.1-8.255-35.858 0-22.702 5.59-40.244 16.769-52.627 11.35-12.555 27.431-18.832 48.24-18.832zm-1.547 29.409c-13.759 0-21.928 8.599-24.508 25.797h48.241c-1.204-9.287-3.698-15.908-7.481-19.864-3.612-3.955-9.029-5.933-16.252-5.933z"
        fill="#fff"
      />
    </svg>
  )
}
