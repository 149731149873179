/**
 * Component
 *
 * Component type: droverSection
 *
 */

import React from 'react'
import { getVariation } from '@wheelroom/core'
import { TopicSectionModel } from '@wheelroom/wheel-topic'
import { DroverSectionHeroVar } from './drover-section-hero-var'
import { DroverSectionQuoteVar } from './drover-section-quote-var'

const componentList = {
  hero: DroverSectionHeroVar,
  quote: DroverSectionQuoteVar,
}

export const DroverSection = (props: TopicSectionModel) => {
  const Variation = getVariation(props, componentList)

  return <Variation {...props} />
}
