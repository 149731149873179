/**
 * Component variation
 *
 * Component type: topicSection
 * Variation: Hero
 *
 */

import { getSectionWheel } from '@wheelroom/core'
import {
  TopicSectionModel,
  TopicModel,
  Topic,
  getTopicOptions,
} from '@wheelroom/wheel-topic'
import React from 'react'
import { SvgLogoWit } from './logo-wit'
import { TopicSectionWrapper } from './topic-section-wrapper'

export const DroverSectionHeroVar = (props: TopicSectionModel) => {
  const sectionWheel = getSectionWheel({
    themeId: props.theme || props.activeThemeId,
    wheelId: 'topicSection',
    sectionWheels: props.sectionWheels,
    variation: 'heroDrover',
  })

  if (!sectionWheel || !props.topics) {
    return null
  }
  const { data, wheel } = sectionWheel

  const topicHeading = props.index <= 1 ? 'h1' : 'h2'
  return (
    <TopicSectionWrapper containerStyle="fluid" wheel={wheel}>
      {props.topics.slice(0, 1).map((topic: TopicModel, index: number) => (
        <Topic
          data={data}
          key={index}
          topic={topic}
          topicOptions={getTopicOptions({
            optionStrings: props.topicOptions,
            locale: props.locale,
          })}
          useHeadingElement={topicHeading}
          useIconElement={
            <SvgLogoWit wheel={wheel} ncss={{ color: 'white' }} />
          }
          wheel={{ ...wheel, style: wheel.style.topic }}
        />
      ))}
    </TopicSectionWrapper>
  )
}
